<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn class="text-capitalize" bottom large color="primary" @click="print()"><v-icon
            class="mr-2">mdi-printer</v-icon> imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table :headers="headers" :items="products.data" :items-per-page="products.per_page" disable-sort dense
          hide-default-footer>
          <template v-slot:[`item.price_cash`]="{ item }">
            {{ $format.decimal(item.product_variants[0].price_cash) }}
          </template>

          <template v-slot:[`item.price_forward`]="{ item }">
            {{ $format.decimal(item.product_variants[0].price_forward) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="addProduct(item)" text icon color="primary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn @click="removeProduct(item)" text icon color="primary">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <v-data-table :headers="selectedHeaders" :items="selectedProducts" :items-per-page="selectedProducts.length"
          disable-sort dense hide-default-footer>
          <template v-slot:[`item.price_cash`]="{ item }">
            {{ $format.decimal(item.product_variants[0].price_cash) }}
          </template>

          <template v-slot:[`item.price_forward`]="{ item }">
            {{ $format.decimal(item.product_variants[0].price_forward) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Cod.", value: "code" },
        { text: "Descrição", value: "name" },
        { text: "Preço Av", value: "price_cash" },
        { text: "Preço Pz", value: "price_forward" },
        { text: "", value: "action", width: "25%" },
      ],
      selectedHeaders: [
        { text: "Cod.", value: "code" },
        { text: "Descrição", value: "name" },
        { text: "Preço Av", value: "price_cash" },
        { text: "Preço Pz", value: "price_forward" },
        {
          text: "Qt Etiquetas",
          value: "quantity",
          width: "25%",
          align: "center",
        },
      ],
      products: {},

      sarchParams: { text: null },
    };
  },

  created() {
    this.select();
  },
  computed: {
    selectedProducts() {
      return this.$store.state.productsPrintTag.products;
    },
  },
  methods: {
    select() {
      this.sarchParams.status = 1;
      this.$http
        .index("product/product", this.sarchParams)
        .then((response) => {
          this.products = response.products;
        })
        .catch((error) => { });
    },
    handleSearchInput(text) {
      this.sarchParams.text = text;
      this.select();
    },

    addProduct(product) {
      this.$store.commit("productsPrintTag/increaseProduct", {
        product: product,
        quantity: 1,
      });
    },
    removeProduct(product) {
      this.$store.commit("productsPrintTag/decreaseProduct", product);
    },
    print() {
      window.open("/produtos/etiquetas/imprimir");
    },
  },
};
</script>

<style></style>
